import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'

export default class ArrayField extends Field {
  type: FieldTypes = FieldTypes.array
  children: Field[] = []
  firstDelete: boolean = true
  additionalActions: any[] = []
  deleteButtonProps = {
    placement: 'end',
    class: '',
    color: 'error',
    elevation: undefined
  }
  addButtonProps = {
    isIcon: false,
    class: 'mb-4',
    style: ''
  }
  justifyChildren: string = 'justify-start'
  initialLength: number = 1
  nonDeletableAmount: number = 0

  addButton: (value: any, entry: any) => boolean = (value: any, entry: any) => true

  setChildren(children: Field[]): this {
    this.children = children
    return this
  }

  setFirstDelete(firstDelete: boolean): this {
    this.firstDelete = firstDelete
    return this
  }

  setAdditionalActions(components: any[]): this {
    this.additionalActions = components
    return this
  }

  setAddButton(addButton: (value: any, entry: any) => boolean): this {
    this.addButton = addButton
    return this
  }

  setDeleteButtonProps(deleteButtonProps: any): this {
    this.deleteButtonProps = deleteButtonProps
    return this
  }

  setJustifyChildren(justifyChildren: string): this {
    this.justifyChildren = justifyChildren
    return this
  }

  setAddButtonProps(addButtonProps: any): this {
    this.addButtonProps = addButtonProps
    return this
  }

  setInitialLength(initialLength: number): this {
    this.initialLength = initialLength
    return this
  }

  setNonDeletableAmount(nonDeletableAmount: number): this {
    this.nonDeletableAmount = nonDeletableAmount
    return this
  }
}
