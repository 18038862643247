import Model from '@/shared/classes/model'
import IHseRiskCategoryResponse from "@/shared/modules/hse-risk-categories/hse-risk-category-response.interface";

export default class HseRiskCategory extends Model<IHseRiskCategoryResponse> {
  name!: string

  transform(data: IHseRiskCategoryResponse): void {
    super.transform(data)

    this.name = data.name
  }
}
