import Model from '@/shared/classes/model'
import IHseRiskCategoryResponse from "@/shared/modules/hse-risk-categories/hse-risk-category-response.interface";
import HseRiskCategory from "@/shared/modules/hse-risk-categories/hse-risk-category.model";
import IAssignedHseRisksResponse from "@/shared/modules/assigned-hse-risks/assigned-hse-risks-response.interface";
import IUserResponse from "@/shared/modules/user/user-response.interface";
import User from "@/shared/modules/user/user.model";
import moment from "moment";
import { getVersion } from "@/shared/helpers/version.helper";

export default class AssignedHseRisks extends Model<IAssignedHseRisksResponse> {
  hseRiskCategoryUuid!: string
  hseRiskCategory!: IHseRiskCategoryResponse
  hseRiskCategoryName!: string
  hseRiskTemplateUuid!: string
  companyHseRiskTemplateUuid!: string
  name!: string
  barriers!: any
  companyUuid!: string
  responsibleUserUuid!: string
  responsibleUser!: IUserResponse
  responsibleUserName!: string
  assessmentDate!: string
  status!: number
  probability!: number
  consequences!: number


  transform(data: IAssignedHseRisksResponse): void {
    super.transform(data)

    this.hseRiskCategoryUuid = data.hseRiskCategoryUuid
    this.hseRiskCategoryName = data.hseRiskCategory?.name
    this.hseRiskTemplateUuid = data.hseRiskTemplateUuid
    this.companyHseRiskTemplateUuid = data.companyHseRiskTemplateUuid
    this.name = data.name
    this.barriers = data.barriers && data.barriers.map((barrier: any) => {
      let date = barrier.date
      if (barrier.date && typeof barrier.date === 'object') {
        date = Object.values(barrier.date)?.[0] || null
      }

      return { ...barrier, date: date }
    })
    this.companyUuid = data.companyUuid
    this.responsibleUserUuid = data.responsibleUserUuid
    this.responsibleUserName = (data.responsibleUser as any)?.fullName
    this.assessmentDate = moment(data.assessmentDate).format(getVersion() === 'no' ? 'DD-MM-YYYY' : 'YYYY-MM-DD')
    this.status = data.status
    this.probability = data.probability
    this.consequences = data.consequences

    this.hseRiskCategory = this.setRelation(HseRiskCategory, data.hseRiskCategory)
    this.responsibleUser = this.setRelation(User, data.responsibleUser)
  }
}
