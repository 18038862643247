































































































import { Component, Prop } from "vue-property-decorator";
import FormField from '@/shared/components/form/FormField.vue'
import LanguageChange from '@/shared/components/form/LanguageChange.vue'
import __ from '@/shared/helpers/__'
import Group from '@/shared/components/form/Group.vue'
import AbstractForm from "@/shared/classes/form/abstract-form";
import { LOCALES } from "@/shared/constants/locales.constants";
import localeHelper from "@/shared/helpers/locale.helper";

@Component({
  components: {
    Group,
    LanguageChange,
    FormField,
  },
  methods: {
    __,
  },
})
export default class RiskTemplateForm extends AbstractForm {
  @Prop() noRightPadding!: boolean
  @Prop() submitOnMount!: boolean
  @Prop() goBack!: () => void
  @Prop() closeBtn!: boolean

  mounted() {
    if (this.submitOnMount) this.submit()
  }

  get systemLanguage(): string {
    return localeHelper.getLocale()
  }

  get getFlag(): string {
    return LOCALES[this.systemLanguage].flag
  }

  get mainFields() {
    const indexOfName = this.form.fields.findIndex(field => field.key.includes('name'))
    if (indexOfName !== -1) return this.form.fields.slice(0, indexOfName)
    return []
  }

  get nameFields() {
    const indexOfName = this.form.fields.findIndex(field => field.key.includes('name'))
    if (indexOfName !== -1) return this.form.fields.slice(indexOfName, indexOfName + 2)
    return []
  }

  get barrierFields() {
    const indexOfBarriers = this.form.fields.findIndex(field => field.key.includes('barriers'))
    if (indexOfBarriers !== -1) return this.form.fields.slice(indexOfBarriers, indexOfBarriers + 1)
    return []
  }

  get probabilityAndConsequencesFields() {
    const indexOfProbability = this.form.fields.findIndex(field => field.key.includes('probability'))
    if (indexOfProbability !== -1) return this.form.fields.slice(indexOfProbability)
    return []
  }
}
